var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ws-landing',{attrs:{"headline":"Store anything with us NOW","subtitle":"","points":[
    'Short & long term storage',
    'Dry, ambient, chilled, and frozen storage available',
    'Pay per use/pay as you go9oo storage',
    'Inventory management',
    'Affordable storage',
    'Warehouse space anywhere across UAE',
    'Freezone & mainland locations' ],"image":require('../../assets/landing/warehous&stroage.jpg'),"form-title":"Submit your request NOW","submitTitle":"Try for free","bottom-line":"Easy, On-Demand Warehousing and Delivery"}})}
var staticRenderFns = []

export { render, staticRenderFns }