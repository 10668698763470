var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ws-landing',{attrs:{"headline":"Need freight forwarding services ?","subtitle":" ","points":[
    'International shipping',
    'Customs clearance',
    'Flexible time & volume of storage',
    'Temperature-controlled options available',
    'All good types accommodated',
    'No long-term agreement',
    'No account management fees',
    'Serving all businesses/industries' ],"image":require('../../assets/landing/ff.png'),"form-title":"Submit your request NOW","submitTitle":"Try for free","bottom-line":"Easy, On-Demand Warehousing and Delivery"}})}
var staticRenderFns = []

export { render, staticRenderFns }