var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ws-landing',{attrs:{"headline":"Do you need to ship your goods internationally?","subtitle":"Let us help you with your business ","points":[
    'Sea Air, and land freight ',
    'Customs clearance ',
    'Product registration ',
    'IOR (Importer on Record)',
    'Cross-border shipment',
    'Cross-trade shipment',
    'All types of commodities accommodated' ],"image":require('../../assets/landing/supply.jpg'),"form-title":"Submit your request NOW","submitTitle":"Try for free","bottom-line":"Easy, On-Demand Warehousing and Delivery"}})}
var staticRenderFns = []

export { render, staticRenderFns }