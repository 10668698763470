var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ws-landing',{attrs:{"headline":"Do you need fast and efficient fulfilment services in UAE?","subtitle":"Let us help you with your business ","points":[
    'Pick & pack ',
    'eCommerce fulfilment',
    'Customized packaging',
    'Labelling and tagging',
    'Inventory and stock management',
    'Dry, ambient, chilled, and frozen fulfilment centres',
    'Freezone & mainland locations',
    'API integrations',
    'Distribution services' ],"image":require('../../assets/landing/fullfilment.jpg'),"form-title":"Submit your request NOW","submitTitle":"Try for free","bottom-line":"Easy, On-Demand Warehousing and Delivery"}})}
var staticRenderFns = []

export { render, staticRenderFns }