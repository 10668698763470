var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ws-landing',{attrs:{"headline":"Are you looking for a last mile delivery ?","subtitle":"Let us help you with your business ","points":[
    'On-demand, same day, and next day delivery ',
    'Return delivery',
    'Exchange order delivery',
   'UAE & GCC delivery',
   'International delivery',
    'Assembly delivery service',
    'Order tracking',
    'COD collection',
    'Distribution services'
  ],"image":require('../../assets/landing/last_mile.png'),"form-title":"Submit your request NOW","submitTitle":"Try for free","bottom-line":"Easy, On-Demand Warehousing and Delivery"}})}
var staticRenderFns = []

export { render, staticRenderFns }