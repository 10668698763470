var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ws-landing',{attrs:{"headline":"Do you have an e-commerce business?","subtitle":"Let us help you with your business ","points":[
    'Efficient software integrations',
   'Affordable prices with <span style=color:#e33064>one week FREE trial</span>',
    'Fulfillment services: packaging, labelling, pick & pack, delivery, and more',
    'Warehouse space anywhere across UAE',
    'No account management fees',
    'Easy customer dashboard',
    'Serving all businesses/industries' ],"image":require('../../assets/landing/ecommerce.png'),"second_image":require('../../assets/landing/amazon_shopify.png'),"form-title":"Submit your request NOW","submitTitle":"Try for free","bottom-line":"Easy, On-Demand Warehousing and Delivery"}})}
var staticRenderFns = []

export { render, staticRenderFns }